<template>
  <div class="loading-auth">
    <img v-if="loading" src="@/assets/img/loading.gif" />
  </div>
</template>
<script>
import { getUserInfo, getAiInterLabelConfig } from '@/api/admin'
import { getCompanyInfo } from '@/api/enterprise'
export default {
  data() {
    return {
      loading: true,
    }
  },
  methods: {
    getUserInfo() {
      getUserInfo().then(res => {
        if (res && res.code === 0) {
          this.$store.commit('setUserInfo', { ...res.data, token: this.query.token })

          this.$store.commit('setToken', this.query.token)

          // this.loading = false
          // localStorage.setItem('token', this.query.token)
          this.getCompanyInfo({ companyId: res.data?.companyId })
          this.aiMarkConfig(res.data?.companyId)
        }
      })
    },
    //获取用户AI标注设置配置
    aiMarkConfig(comid) {
      getAiInterLabelConfig({ companyId: comid }).then(res => {
        if (res && res.code === 0) {
          let aicfg = {
            allStatus: res.data.allStatus === 1 ? true : false, //AI总分展示状态
            singleStatus: res.data.singleStatus === 1 ? true : false, //AI单题分数展示状态
            manualStatus: res.data.manualStatus === 1 ? true : false, //人工标注状态
            aiScoreStatus: res.data.aiScoreStatus === 1 ? true : false, //人工标注后，ai单题展示状态
          }
          this.$store.commit('SET_AICFG', aicfg)
        }
      })
    },
    getCompanyInfo(form) {
      getCompanyInfo(form).then(res => {
        if (res && res.code === 0) {
          this.$store.commit('setShowAiVideo', res.data.isShowAiVideo)
          // 6a58b5a5d76f4cfe9b0b99d0d553f5ce
          this.$store.commit('setUserInfo', { ...this.$store.state.userInfo, ...res.data, token: this.query.token })
          this.loading = false
          const query = this.$route.query
          if (query && query.path) {
            this.$router.replace({
              path: query.path,
              query: {
                ...query,
              },
            })
          } else {
            this.$router.replace({
              path: '/first/talentList',
            })
          }
        }
      })
    },
  },
  mounted() {
    console.log('authmounted')
    if (this.query && this.query.token) {
      localStorage.setItem('token', this.query.token)
      this.$store.commit('setUserInfo', { token: this.query.token })
      this.getUserInfo()
    } else {
      this.$toast({ type: 'waring', message: '缺少Token' })
    }
  },
  computed: {
    query() {
      return this.$route.query || ''
    },
  },
  watch: {
    $route: {
      immediate: true,
      deep: true,
      handler() {
        console.log('this.$route', this.$route)
      },
    },
  },
}
</script>
<style lang="scss" scoped>
.loading-auth {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
